.App {
  text-align: center;
}

.App-logo {
  height: 40vm;
  pointer-events: none;
}

/* ==================================ANIMATION EFFECTS CSS START===================================== */

/* Define zoom-in animation */
@keyframes zoomIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Slide Down Animation */
@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Slide Up Animation */
@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Zoom Up Animation */
@keyframes zoomUp {
  0% {
    transform: scale(0.8) translateY(100%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

/* Zoom Down Animation */
@keyframes zoomDown {
  0% {
    transform: scale(0.8) translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

/* Apply zoom-in animation to the content inside the PageHeader */
.zoom-in {
  animation: zoomIn 1s ease-in-out; /* 1s animation with ease-in-out timing */
  transform-origin: center; /* Start the zoom effect from the center */
  opacity: 1; /* Ensure it's visible */
}

/* Slide Down */
.slide-down {
  animation: slideDown 1s ease-in-out;
  opacity: 1;
}

/* Slide Up */
.slide-up {
  animation: slideUp 1s ease-in-out;
  opacity: 1;
}

/* Zoom Up */
.zoom-up {
  animation: zoomUp 1s ease-in-out;
  opacity: 1;
}

/* Zoom Down */
.zoom-down {
  animation: zoomDown 1s ease-in-out;
  opacity: 1;
}

/* ==================================ANIMATION EFFECTS CSS END===================================== */

/* ==================================PAGE HEADER COMPONENT CSS START===================================== */

/* src/App.css or custom CSS file */

/* Custom Call Us button styling */
.btn-primary {
  background-color: #ff6347; /* Custom button color */
  border-color: #ff6347;
}

.btn-primary:hover {
  background-color: #ff4500; /* Darker color on hover */
  border-color: #ff4500;
}

.btn {
  padding: 10px 30px;
  font-size: 1.3rem;
  font-weight: 600;
  border-radius: 15px;
  font-family: "Lora", serif;
  cursor: pointer;
}

.btn-custom {
  background-color: red; /* Red background */
  border: 2px solid white; /* White border */
  color: white; /* White text */
  transition: all 0.3s ease; /* Smooth transition on hover */
}

.btn-custom:hover {
  background-color: white; /* White background on hover */
  border: 2px solid red; /* Red border on hover */
  color: black; /* Black text on hover */
}

/* ==================================PAGE HEADER COMPONENT CSS END===================================== */



/* ==================================GALLERY COMPONENT CSS START===================================== */

/* Full-width gallery */
.gallery-container {
  width: 100%;
  margin: 0 auto;
}

.react-photo-gallery--gallery img {
  transition: transform 0.3s ease-in-out; /* Smooth transition for scaling effect */
}

.react-photo-gallery--gallery img:hover {
  transform: scale(1.1); /* Scale image by 10% on hover */
}

/* Style for "Show More" button */
.btn-primary {
  background-color: #ff6347;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary:hover {
  background-color: #ff4500;
}

/* Remove borders from images */
.gallery img {
  border: none;
  box-shadow: none;
}

/* ==================================GALLERY COMPONENT CSS END===================================== */




/* ==================================CONTACT FORM COMPONENT CSS START===================================== */



/* General form styles */
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.form-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-control {
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px; /* Add border radius here */
  background-color: #f5f5f5;
  color: #999;
  outline: none;
  transition: all 0.3s ease;
}

.form-control::placeholder {
  color: #999;
}

.form-control:focus {
  border-color: #000;
  background-color: #fff;
}

textarea.form-control {
  height: 150px;
  resize: none;
  border-radius: 8px; /* Ensure border radius for textarea as well */
}

/* Submit button styles */
.btn-submit {
  padding: 15px 40px;
  font-size: 1.2rem;
  border: 2px solid black;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  border-radius: 25px; /* Add border radius for the button */
}

.btn-submit:hover {
  background-color: red;
  color: white;
}



/* ==================================CONTACT FORM COMPONENT CSS END===================================== */



/* ==================================AREASWECOVERMAP COMPONENT CSS START===================================== */

.areas-we-cover-section {
  margin: 3rem 0;
  text-align: center;
}

.city-names {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 2rem;
}

.city-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.map-container {
  width: 100%;
  height: 400px;
}
/* ==================================AREASWECOVERMAP COMPONENT CSS END===================================== */




/* ==================================HEADER CSS START===================================== */

/* src/App.css or custom CSS file */

/* Lora font for menu items */
.menu-item {
  font-family: "Lora", serif;
  font-weight: 400; /* Regular weight for menu */
  font-size: 1.1rem; /* Adjust size as needed */
  text-transform: uppercase; /* Make the menu text uppercase */
}

/* Align the icons and text vertically */
.navbar-nav .menu-item {
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Center the items in mobile view */
  padding: 10px 20px; /* Padding to make the items more button-like */
  border: 2px solid transparent; /* Initial button border */
  border-radius: 8px; /* Rounded corners for button-like appearance */
  text-decoration: none; /* Remove underline */
  color: white; /* Text color */
  font-size: 1.1rem; /* Increase font size */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  width: auto; /* Let items adjust to content by default */
}

/* Style the icons */
.navbar-nav .menu-icon {
  font-size: 1.1rem; /* Icon size */
  margin-right: 10px; /* Space between icon and text */
}

/* Hover effect for buttons */
.navbar-nav .menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Button hover background */
  border: 2px solid rgba(255, 255, 255, 0.3); /* Hover border */
  color: white; /* Keep the text white on hover */
}

/* Button effect: Change text color and background on hover */
.navbar-nav .menu-item:hover .menu-icon {
  color: white; /* Keep icon white on hover */
}

/* Full-width hover effect on mobile */
@media (max-width: 992px) {
  /* Applies to screens smaller than 992px (Bootstrap breakpoint for mobile) */
  .navbar-nav .menu-item {
    width: 100%; /* Make the items full width on mobile */
  }
}

/* Style the icons */
.navbar-nav .menu-icon {
  font-size: 1.5rem; /* Icon size */
  margin-right: 10px; /* Space between icon and text */
}

/* Hover effect for buttons */
.navbar-nav .menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Button hover background */
  border: 2px solid rgba(255, 255, 255, 0.3); /* Hover border */
  color: white; /* Keep the text white on hover */
}

/* Full-width hover effect */
@media (max-width: 992px) {
  .navbar-nav .menu-item:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Hover effect full width */
    width: 100%; /* Ensure the background covers full width */
  }
}

/* ===================================HEADER CSS END==================================== */

/* ===================================FOOTER CSS START==================================== */

/* Add a shadow to the top of the footer */
footer {
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1); /* Adjust values as needed */
  font-family: "Lora", serif;
  padding-top: 20px;
}

.company-footer-name {
  font-family: "Lora", serif;
  font-weight: 700;
}

/* Ensure that the footer content is properly spaced and aligned */
.footer-logo-link h2 {
  font-size: 1.5rem;
  font-weight: 700;
}

.contact-info-header {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.footer p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.contact-info a {
  text-decoration: none;
  color: #000;
}

.contact-info a:hover{
  color: black;
  font-weight: bold;
}

.contact-info a:visited{
  color: black;
  font-family: "Lora", serif;
}


.social-link {
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
}

.social-link:hover {
  color: #ff6347; /* Add hover color if needed */
  font-weight: 700;
}
/* Style for social media links and logo link */
.footer-logo-link,
.social-link {
  color: black; /* Ensure the link is black by default */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition for hover effect */
  font-family: "Lora", serif;
}

/* Make sure the link stays black even after being visited */
.footer-logo-link:visited,
.social-link:visited {
  color: black;
  font-family: "Lora", serif;
}

/* Add hover effect */
.footer-logo-link:hover,
.social-link:hover {
  color: rgba(0, 0, 0, 0.8); /* Slightly darker on hover */
  text-decoration: underline; /* Add underline on hover */
  font-family: "Lora", serif;
}

/* ===================================FOOTER CSS END==================================== */

/* ===================================HOME CSS START==================================== */

/* Lora font for header titles */
.header-title {
  font-family: "Lora", serif;
  font-weight: 700; /* Bold weight */
  font-size: 2.7rem; /* Adjust size as needed */
  text-transform: uppercase; /* Make the menu text uppercase */
}

.header-subtitle {
  font-family: "Lora", serif;
  font-weight: 400; /* Regular weight */
  font-size: 2rem; /* Adjust size as needed */
}

.page-heading p {
  font-size: 1.1rem;
}

/* src/App.css or custom CSS file */

/* Section Styles */
.why-choose-section {
  background-color: #f8f9fa; /* Light background for contrast */
  padding: 50px 0; /* Top and bottom padding */
}

.section-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #333;
}

/* List Styles */
.why-choose-list {
  list-style-type: none; /* Remove default list bullets */
  padding: 0;
  text-align: left;
}

.why-choose-list li {
  margin-bottom: 15px; /* Space between list items */
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.why-choose-list li strong {
  color: #333; /* Highlighted subheading text */
}

/* Image Styles */
/* .img-fluid {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Rounded corners for the image */
/* } */

@media (max-width: 991.98px) {
  .why-choose-section .col-md-6 {
    width: 100%;
  }
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .why-choose-section {
    padding: 30px 0;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .why-choose-list li {
    font-size: 1rem;
  }
}

/* ===================================HOME CSS END==================================== */

/* ===================================SERVICES CSS START==================================== */

.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  font-family: "Lora", serif;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}
.price-div{
  position: relative;
}
.col-md-6 {
  flex: 0 0 auto;
  width: 50%;
}

.mb-md-0 {
  margin-bottom: 0 !important;
}

.mb-md-5 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.lead {
  font-size: 1.1rem;
  font-weight: 500;
  /* line-height: 1.625; */
  text-align: justify;
  font-family: "Lora", serif;
}
.serviceDesc-p{
  font-size: 1.1rem;
  text-align: justify;
  font-family: "Lora", serif;
}
.price-div{
  display: none;
  margin: 2rem 0;
  text-align: justify;
}

.highlighted-heading {
  font-weight: bold;
  color: black; /* You can choose your preferred color */
}
.py-5 {
  padding-top: 3rem !important;
}

.img-fluid {
  width: auto;
  height: 550px;
  float: right;
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04) !important;
}

.border-radius-lg {
  border-radius: 0.5rem;
}

.max-height-550 {
  max-height: 550px !important;
  object-fit: cover;
}

/* Button styling */
.check-price-btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold; /* Bold text to make it catchy */
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #e74c3c; /* Catchy reddish color */
  color: white; /* White text for contrast */
  border: none; /* No border */
  outline: none; /* No outline */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for background and shadow */
  margin-bottom: 10px;
}

/* Button hover state */
.check-price-btn:hover {
  background-color: #c0392b; /* Slightly darker red on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds shadow effect on hover */
}

/* Button active (clicked) state */
.check-price-btn:active {
  background-color: #a93226; /* Even darker red when clicked */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2); /* Deeper shadow when clicked */
}

/* Dropdown icon spacing */
.dropdown-icon {
  margin-left: 10px;
  font-weight: normal; /* Slight contrast to button text */
}
/* Table container with smooth height transition */
.price-dropdown {
  position: absolute;
  z-index: 10; /* Ensures dropdown stays on top of other elements */
  max-height: 0; /* Collapsed initially */
  overflow: hidden; /* Hide content when collapsed */
  margin-top: 10px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow around the table */
  border-radius: 5px;
  opacity: 0; /* Initially invisible */
  transition: max-height 0.5s ease, opacity 0.5s ease; /* Smooth transition for height and opacity */
}

/* Transition for showing the dropdown */
.price-dropdown.show {
  max-height: 1000px; /* Expand the table */
  opacity: 1; /* Make it visible */
}

/* Table styling */
.price-dropdown table {
  width: 100%;
  border-collapse: collapse; /* No gap between cells */
  border-spacing: 0;
}

/* Styling for table headers */
.price-dropdown th {
  padding: 7px;
  text-align: center;
  font-size: 16px;
  font-weight: bold; /* Bold headers */
  background-color: #f9f9f9; /* Light background for header */
  border-bottom: 2px solid #ddd; /* Border below headers */
}

/* Styling for table data cells */
.price-dropdown td {
  padding: 10px;
  text-align: center;
  font-size: 14px;
  background-color: #fff; /* White background for cells */
}
.custom-td{
  padding-left: 2.5rem !important;
}
.custom-td-0{
  text-align: center;
}
.custom-td-1{
  border-right: none !important;
}
.custom-tr-car-type{
  border-right: 2px solid #ddd; /* Line between columns */
}
/* Border between Personal Vehicle and Commercial Vehicle columns */
.price-dropdown td, .price-dropdown th {
  border-right: 2px solid #ddd; /* Line between columns */
}
/* Optional: Add a little space between the button and the table */
.check-price-btn + .price-dropdown {
  margin-top: 15px;
}

@media (max-width: 550px){
  .price-dropdown.show{
    display: block !important;
  }
  .price-dropdown{
    bottom: 0px !important;
    position: unset ;
  }
  .dropdown-icon {
    transform: unset !important; /* Rotate icon to point right */
  }
} 
@media (max-width: 836px){
  .row{
    display: grid;
  }
  .col-md-6{
    width: 100% !important;
  }
  .price-dropdown{
    display: inline !important;
  }
  .price-dropdown{
    width: auto !important;
    margin-top: 0 !important;
    bottom: -35px !important;
  }
  .price-dropdown th{
    font-size: 10px !important;
  }
  .price-dropdown td{
    font-size: 10px !important;
  }
  .price-div{
    margin: 4rem 0 !important;
  }
  .max-height-550{
    max-height: 350px !important;
    width: 100%;
  }
  .mb-4{
    margin-bottom: 0 !important;
  }
  .dropdown-icon {
    transform: rotate(-90deg); /* Rotate icon to point right */
  }
}
/* ===================================SERVICES CSS END==================================== */

/* DEFAULT CSS */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ===================================PRICING CSS STARTS==================================== */
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: red;
  font-weight: 600;
}
.badge{
  font-size: 1rem;
}
.bg-primary {
  background-color: red !important;
}
.text-lg{
  font-size: 1.5rem !important;
}
.material-icons{
  font-size: 15px !important;
}
.ps-3{
  font-size: 15px !important;
}
.bg-gradient-info{
  background-image: linear-gradient(195deg, rgba(49, 49, 59, 0.7) 0%, rgba(25, 25, 25, 0.7) 100%);
}
.bg-info {
  --bs-bg-opacity: 1;
  background-color: #dc2e59 !important;
}

.note-text {
  /* margin-top: 10px; */
  font-size: 1rem;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #FFF;
  font-style: italic;
}

.tabs {
  margin-top: -250px;
}


@media (min-width: 992px) {
  .col-lg-3 {
      flex: 0 0 auto;
      width: 33.33%;
  }
}
/* ===================================PRICING CSS END==================================== */